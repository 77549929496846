<template>
    <div class="flex bg-background bg-stone-100 px-6 py-4">
      <h1 class="text-2xl font-bold text-stone-700">节点订阅服务</h1>
    </div>
  
    <div class="flex w-full min-h-screen justify-center items-start bg-stone-50">
        <div class="lg:w-1/3 w-3/4 pt-8">

            <div class="w-full text-left text-stone-950 bg-stone-100 p-3 pl-5 m-3 rounded-md">
                <!-- 设置为 flex 布局，使内容在一行显示 -->
                <div class="flex items-center">
                <i class="material-symbols--border-all-rounded mr-3 align-top"></i>
                <span>生成订阅链接</span>
                <!-- 使用 ml-auto 将按钮推到最右边 -->
                <button 
                    id="btn_generate_link"
                    @click="generateLink"
                    class="ml-auto bg-stone-700 border-b-4 border-stone-800 hover:bg-stone-600 hover:border-stone-700 text-stone-50 text-sm font-semibold py-2 px-4 rounded"
                >创建
                </button>
                </div>
            </div>

            <div class="w-full text-left text-stone-950 bg-stone-100 p-3 pl-5 m-3 rounded-md">
                <!-- 设置为 flex 布局，使内容在一行显示 -->
                <div class="flex items-center">
                    <i class="material-symbols--search mr-3 align-top"></i>
                    <span>订阅使用情况</span>
                    <!-- 使用 ml-auto 将按钮推到最右边 -->
                    <button 
                        id="btn_show_subscription_usage"
                        @click="showSubscriptionUsage"
                        class="ml-auto bg-stone-700 border-b-4 border-stone-800 hover:bg-stone-600 hover:border-stone-700 text-stone-50 text-sm font-semibold py-2 px-4 rounded"
                    >查看
                    </button>
                </div>
            </div>

            <div class="w-full text-left text-stone-950 bg-stone-100 p-3 pl-5 m-3 rounded-md">
                <!-- 设置为 flex 布局，使内容在一行显示 -->
                <div class="flex items-center">
                <i class="weui--setting-filled mr-3 align-top"></i>
                <span>高级选项</span>
                <!-- 使用 ml-auto 将按钮推到最右边 -->
                <button 
                    id = "btn_setting"
                    @click="getSettings"
                    class="ml-auto bg-stone-700 border-b-4 border-stone-800 hover:bg-stone-600 hover:border-stone-700 text-stone-50 text-sm font-semibold py-2 px-4 rounded"
                >设置
                </button>
                </div>
            </div>

        </div>
    </div>

    <!-- 模态弹窗 -->
    <InfoModal 
      :isOpen="isInfoModalOpen" 
      :title="modalTitle" 
      :content="modalContent" 
      @close="handleCloseModal"
    />
    <InfoModalCopy 
      :isOpen="isInfoModalCopyOpen" 
      :title="modalTitle" 
      :content="modalContent" 
      @close="handleCloseModal"
    />
    <SettingModal 
      :isOpen="isSettingModalOpen" 
      :settingData="settingData"
      @close="handleCloseModal"
    />
    <ShowSubscriptionModal 
      :isOpen="isShowSubscriptionModalOpen" 
      :content="subscriptionData" 
      @close="handleCloseModal"
    />
  </template>
  
<script>
import InfoModal from '@/components/InfoModal.vue';
import InfoModalCopy from '@/components/InfoModalCopy.vue';
import SettingModal from '@/components/SettingModal.vue';
import ShowSubscriptionModal from '@/components/ShowSubscriptionModal.vue';
import api from '../api'; // 导入封装好的API模块

export default {
    name: "DashboardPage",
    components: {
        InfoModal,
        InfoModalCopy,
        SettingModal,
        ShowSubscriptionModal,
    },
    data() {
        return {
            modalTitle: '',
            modalContent: '',
            subscriptionData: [],
            settingData: [],
            isInfoModalOpen: false, 
            isInfoModalCopyOpen: false, 
            isSettingModalOpen: false,
            isShowSubscriptionModalOpen: false,
        };
    },
    computed: {
    },
    methods: {
        showInfo(title, content, copy = false) {
            this.modalTitle = title;
            this.modalContent = content;
            // 直接传递参数，不需要 copy=copy
            this.openModal(copy);
        },
        openModal(copy) {
            // 根据 copy 参数的值判断哪个模态框打开
            if (copy) {
                this.isInfoModalCopyOpen = true;
            } else {
                this.isInfoModalOpen = true;
            }  
        },
        handleCloseModal() {
            this.isInfoModalOpen = false;
            this.isInfoModalCopyOpen = false;
            this.isSettingModalOpen = false;
            this.isShowSubscriptionModalOpen = false;
        },
        openSettingModal(){
            this.isSettingModalOpen = true;
        },
        showSubscriptionModal(){
            this.isShowSubscriptionModalOpen = true;
        },
        async generateLink() {
            var button_element = document.getElementById('btn_generate_link');
            // set button disabled
            button_element.disabled = true;
            button_element.innerHTML = `
<span>
创建中
<i aria-hidden="true" role="status" class="inline-block rounded-full animate-spin icon--loading align-middle" />
</span>`;
            // get status
            var data = {
                token: this.$store.state.token
            }
            const response = await api.generateLink(data);
            
            if (response.data.result) {
                var currentDomain = window.location.origin;
                var subscribe_link = currentDomain + '/subscribe?token=' + response.data.data
                this.showInfo('订阅创建成功', subscribe_link, true);
            } else {
                this.showInfo('订阅创建失败', response.data.err);
            }
            button_element.disabled = false;
            button_element.innerHTML = '创建';
        },

        async showSubscriptionUsage() {
            var button_element = document.getElementById('btn_show_subscription_usage');
            // set button disabled
            button_element.disabled = true;
            button_element.innerHTML = `
<span>
查询中
<i aria-hidden="true" role="status" class="inline-block rounded-full animate-spin icon--loading align-middle" />
</span>`;
            // get status
            var data = {
                token: this.$store.state.token
            }
            const response = await api.showSubscriptionUsage(data);
            
            if (response.data.result) {
                this.subscriptionData = response.data.data;
                this.showSubscriptionModal();
            } else {
                this.showInfo('订阅查询失败', response.data.err);
            }
            button_element.disabled = false;
            button_element.innerHTML = '查看';
        },

        async getSettings() {
            var button_element = document.getElementById('btn_setting');
            // set button disabled
            button_element.disabled = true;
            button_element.innerHTML = `
<span>
获取设置
<i aria-hidden="true" role="status" class="inline-block rounded-full animate-spin icon--loading align-middle" />
</span>`;
            // get status
            var data = {
                token: this.$store.state.token
            }
            const response = await api.getSettings(data);
            
            if (response.data.result) {
                this.settingData = response.data.data;
                this.openSettingModal();
            } else {
                this.showInfo('设置获取失败', response.data.err);
            }
            button_element.disabled = false;
            button_element.innerHTML = '设置';
        },
    },
}
</script>
  
<style>
html, body {
  overflow: hidden;
}
</style>
  
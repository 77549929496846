<template>
  <transition
    name="modal-fade"
    @leave="leave"
  >
  <div v-if="isOpen" class="fixed inset-0 flex items-center justify-center bg-black bg-opacity-20" @click="closeModal">
      <!-- 你的模态框内容 -->
      <div id="modal_window" class="p-6 rounded-lg shadow-lg w-3/4 lg:w-1/6 md:w-1/3 animate__animated animate__fadeInUp bg-stone-100" @click.stop>
        <!-- Close Icon -->
        <button @click="closeModal" class="absolute top-0 right-0 px-2.5 py-1.2 text-stone-100 bg-stone-700 hover:bg-stone-800 focus:outline-none rounded-tr-lg rounded-bl-lg">
          <span class="material-symbols--close-small-outline-rounded">close</span>
        </button>
        <h2 class="text-lg mb-4 font-bold text-left text-stone-900">
          <i class="material-symbols--note text-md" style="vertical-align: middle; line-height: 1;" />
          {{ title }}
        </h2>
        <div class="mb-4 text-left text-stone-900 truncate-content" :title="content">
          <slot>
            {{ content }}
          </slot>
        </div>
        <div class="flex justify-end space-x-2">
          <span v-if="copySuccess" class="absolute left-5 text-sm text-stone-900 dark:text-stone-300">复制成功！</span>
          <button 
            id="copy_btn"
            @click="copyContent" 
            class="px-4 py-1.5 rounded bg-opacity-90 hover:text-stone-50 bg-stone-700 hover:bg-stone-600 text-white"
          >
            复制链接
          </button>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
import 'animate.css';

export default {
  name: 'InfoModalCopy',
  data() {
    return {
      copySuccess: false,
    };
  },
  props: {
    isOpen: {
      type: Boolean,
      required: true,
    },
    title: {
      type: String,
      default: 'null',
    },
    content: {
      type: String,
      default: 'null',
    }
  },
  methods: {
    closeModal() {
      this.$emit('close');
    },
    leave(el) {
      const modal_element = el.querySelector('#modal_window'); // 声明并获取 modal_element
      if (modal_element) {
        modal_element.classList.remove('animate__fadeInUp');
        modal_element.classList.add('animate__fadeOutDown');
      }
    },
    copyContent(){
      // button
      var button_element = document.getElementById("copy_btn")
      button_element.disabled = true;
      // content
      navigator.clipboard.writeText(this.content).then(() => {
        this.copySuccess = true; // 显示复制成功提示
      setTimeout(() => {
        this.copySuccess = false; // 2秒后隐藏提示
      }, 2000);
      }).catch(err => {
        console.error('复制失败:', err);
      });
      button_element.disabled = false;
    }
  }
};
</script>


<style scoped>
.modal-fade-enter-active,
.modal-fade-leave-active {
  transition: opacity 0.5s;
}
.modal-fade-enter, .modal-fade-leave-to /* .modal-fade-leave-active in <2.1.8 */ {
  opacity: 0;
}

.fixed {
  z-index: 9999; /* 确保背景在模态框下 */
}

.truncate-content {
  white-space: nowrap;        /* 强制内容不换行 */
  overflow: hidden;           /* 隐藏超出的内容 */
  text-overflow: ellipsis;    /* 显示省略号 */
  max-width: 64ch;            /* 限制显示32个字符的宽度 */
}
</style>
